


.price-card{
  background-color:white;
}
.price-row-del {
  background-color:white;
}
.intro-text, .about-text {
  font-size: 150%;
}

/*  font-size: 1rem; */
, #qt1x
, .testa-carda {
  color: green;
  font-size: 1.2rem;
}
, #qt1s
, .b-shta
{
  color:red;
}

/* B&I Specific CSS */
.navbar-style {
  background: linear-gradient(to right, #FBFFFF, #6E7878);
  color:white;
}
.navbar-dark .navbar-nav .nav-link {
  color: hsla(0,0%,100%,.7)
}


.imgoverlay-text {
  color:white;
  text-shadow: 1px 1px gray;
  font-size: 250%;
}

/* background-color:#F4F8FB; */
body , .main-content  {
  background-color:#EBECED;
}

/* background-color:#F4F8FF; */
.info-card, .section-info,
.about-head, .testa-head, .quest-head, .testa-card, .quest-card {
  background-color:#EBECED;
}

/*  background-color:#5C646A;*/
/*  background-color:#4A688E;*/
.logo-colorgray{
  background-color:#0b0f14;
}
.logo-color{
  background-color:#0b0f14;
}


.color-overlay {
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:99;
}
/*
  background-color:rgba(26,66,118,.7);
  background: linear-gradient(to right ,transparent, #FBFFFF 70%, #6E7878);
  background-color:rgba(110,120,120,.6);
  background-color:rgba(70,80,80,.8);
*/
.color-overlay-imaWAS {
  background-color:rgba(60,70,70,.8);
}

.color-intro1{
  background-color:#F4F8FB;
}

.section-info-h {
  color:#212529 !important;
}
.card h3.section-info-h {
  font-weight:500;
}




.intro-row {
  background-color:white;
}
/*   background-color: #6E7878; */
.intro-btn H3 {
  font-size: 100%;
  text-align: center !important;
  margin-bottom:0 !important;
}
.intro-btn A {
  color:#4A688E;
  text-align: center!important;
  text-decoration: none;
}
/*   border: 4px solid #4A688E !important ; */
.intro-btn {
   background-color: #FFFFFF;
   color:#4A688E;
   border: 4px solid #4A688E !important;
   display: block;
}
.intro-btn:hover {
   background-color: #4A688E;
}
.intro-btn A:hover {
   color:#FFFFFF;
}




.price-row {
  background-color:white;
}
/*   background-color: #6E7878; */
.price-btn H3 {
  text-align: center !important;
  margin-bottom:0 !important;
}
.price-btn A {
   color:#4A688E;
   text-align: center!important;
   text-decoration: none;
}
/*   border: 4px solid #4A688E !important ; */
.price-btn {
   background-color: #FFFFFF;
   color:#4A688E;
   border: 4px solid #4A688E !important;
   display: block;
}
.price-btn:hover {
   background-color: #4A688E;
}
.price-btn A:hover {
   color:#FFFFFF;
}





.title-banner-del, .title-image-BR-del {
   display: block;
   margin-left: auto;
   margin-right: auto;
}

.align-items-end {
/*
   display: block;
   position: absolute;
   bottom: 0;
*/
}

.eval-box {
   background-color: #6E7878;
   border: 2px solid #9c9999 !important ;
   display: block;
}
.eval-box a {
    color: #ffffaf;
}

.info-card {
  border: 1px solid #9c9999 !important ;
  margin: 0 6px;
}



.about-head {
  font-size: 100%;
}
.testa-text {
  font-size: 115%;
}
.sect-text {
  font-size: 100%;
}

.intro-text, .about-text {
  font-size: 110%;
}

.testa-title, .quest-title {
  margin: 6px;
}
.card h3.testa-title, .card h3.quest-title {
  font-weight:600;
}
.quest-text,
.quest-card .quest-quote {
  font-size: 110%;
}

.intro-imgtext {
  letter-spacing: 0.2em;
}
.intro-spacer {
  font-size: 250%;
}

.testa-quote-del
{
  color:red;
/*  font-size: 1rem; */
}


.coming-text {
  font-size: 115%;
}

.noti-show {
}
.noti-hide {
  display:none
}
.noti-text {
  color:#4A688E;
}




  .nav-link-del .navbar-nav {
    color: pink;
    }

@media screen and (max-width: 870px){
  .price-btn H3 {
    font-size:16px;
  }
}

@media screen and (max-width: 576px){
  .price-btn H3 {
    font-size:24px;
  }
}

@media screen and (max-width: 520px){
  .navbar-style {
    color: pink;
  }
  .nav-link {
    color: hsla(0,0%,100%,.5);
    display:hidden;
  }
  .navbar-dark .navbar-nav .nav-link {
    color:black;
  }
  .title-image-BR-del {
    width: 200px;
    height: 100px;
  }
  .imgoverlay-text {
    font-size: 100%;
  }
  .intro-spacer {
    font-size: 10%;
  }
  .info-card {
    margin: 4px 4px;
  }
}


#footer {
  display:block;
/*
  color:rgba(255,255,255,.4);
  background-color:rgba(26,66,118);
  background-color:#1A4379;
  background-color:#1c2632;
*/
  color:white;
  background-color:#0b0f14;
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased
}
#footer a {
  color:white;
}
.footer-icon-not {
  background-color:gray;
}

.footer-icon{
  margin:15px
}
.footer-headr p,
.footer-head{
  margin-bottom:0 !important
}

.social-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.social-list .mbr-iconfont-social {
  font-size: 1.3rem;
  color: #fff;
}
.social-list .soc-item {
  margin: 0 .5rem;
}
.social-list a {
  margin: 0;
  opacity: .5;
  -webkit-transition: .2s linear;
  transition: .2s linear;
}
.social-list a:hover {
  opacity: 1;
}
.news-text {
  opacity: .7;
}
.text-white,
.mbr-links-column a{
  opacity: .5;
}
.mbr-links-column a:hover {
  opacity: 1;
}
.footer-copy{
  opacity: .35;
  font-size: 80%;
}
@media (max-width: 767px) {
  .social-list {
    justify-content: center;
    -webkit-justify-content: center;
  }
  .hide-small {
    display:none;
  }
}
@media (max-width: 767px) {
  .footer-lower .copyright {
    margin-bottom: 1rem;
    text-align: center;
  }
}


